<template>
    
        <el-container>
            <el-header height="50px">
                <el-row>
                    <vxe-form size="mini">
                        <vxe-form-item :span="3" title="单据号" field="name" :item-render="{}">
                            <vxe-input v-model="p.code" size="mini" placeholder=""></vxe-input>
                        </vxe-form-item>
                         <!-- <vxe-form-item :span="7" title="账套:" field="name" :item-render="{}">
                            <vxe-checkbox-group v-model="checkList1" >
                                <vxe-checkbox :label="item"  v-for="(item,index) in checkList" :key="(item.account)" :content="item.name" ></vxe-checkbox>
                            </vxe-checkbox-group>
                        </vxe-form-item> -->
                        <vxe-form-item :span="2" field="name" :item-render="{}">
                            <vxe-button size="mini" content="查询" @click="select1"></vxe-button>
                        </vxe-form-item>
                    </vxe-form>
                </el-row>
            </el-header>
            <el-container>
                <div style="width:45%">
                    <vxe-table :cell-style="rowStyle" show-overflow highlight-current-row border show-header-overflow
                    highlight-hover-row size="mini" :data="rs" height="600"
                    @current-change="rowclick">
                        <vxe-table-column field="account" title="账套" width="80"></vxe-table-column>
                        <vxe-table-column field="voucherdate1" title="单据日期" width="100"></vxe-table-column>
                        <vxe-table-column field="code" title="单据号" width="180"></vxe-table-column>
                        <vxe-table-column field="partnername" title="客户" width="150"></vxe-table-column>
                        <vxe-table-column :visible="false" field="priuserdefnvc5" title="供应商" width="100"></vxe-table-column>
                        <vxe-table-column field="id" title="操作" width="120">
                            <template #default="{row, rowIndex }">
                                <!--<vxe-button width="60" size="mini" content="下单" @click="apipu(row)"></vxe-button>
                                <vxe-button width="60" size="mini" content="代发" @click="sycupdate(row)"></vxe-button>-->
                                <a @click="apipu(row)"> &nbsp;下单&nbsp;</a>
                               <!-- <a @click="sycupdate(row)">&nbsp;代发校验&nbsp;  | </a>-->
                            </template>
                        </vxe-table-column>
                    </vxe-table>
                </div>
                <!--
                <div style="width: 22%">
                    <vxe-table :cell-style="rowStyle" show-overflow highlight-current-row border show-header-overflow
                    highlight-hover-row size="mini" :data="rs2" height="600" @checkbox-change="selecta" @checkbox-all="selecta">
                        <vxe-table-column type="checkbox" width="45"></vxe-table-column>
                        <vxe-table-column field="code" title="供应商" width="100"></vxe-table-column>
                        <vxe-table-column field="name" title="供应商名称" width="150"></vxe-table-column>
                    </vxe-table>
                </div>-->

                <div style="width: 55%">
                    <vxe-table :cell-style="rowStyle" show-overflow highlight-current-row border show-header-overflow
                    highlight-hover-row size="mini" :data="rs1" height="600" @checkbox-change="selectb" @checkbox-all="selectb">
                        <vxe-table-column field="inventorycode" title="存货编码" width="150"></vxe-table-column>
                        <vxe-table-column field="inventoryname" title="存货名称" width="200"></vxe-table-column>
                        <vxe-table-column field="specification" title="规格" width="100"></vxe-table-column>
                        <vxe-table-column field="compositionQuantity" title="数量" width="70"></vxe-table-column>
                        <vxe-table-column field="origTaxPrice" title="单价" width="70"></vxe-table-column>
                    </vxe-table>
                </div>
                <el-main>
                </el-main>
            </el-container>
        </el-container>
 
</template>
<script>
    import ax from "@/js/ax.js";
    export default {
        name: "saleorder",
        extends: ax.control(),
        data() {
            return {
                paramssyc:{details:[],
                classes:[],
                },
                p:{
                    code:"",
                },
                rs:[],
                rs1:[],
                rs2:[],
                sale:{},
                checkList: [],
                checkList1: [],
                showCard:false,
                
            }
        },
        mounted() {
            //this.dataset.type = "inventoryclass";
            //this.getaccounts();
            this.dataset.type = "saleorder";
        //    this.select2();
        },
        methods: {
            //  select2() {
            //     let _this = this
            //     ax.base.post("/syc1/selectaccount", _this.p).then(data => {
            //         console.log(data);
            //         _this.checkList = data.data;
            //         // _this.checkList1 = data.data;
            //         _this.showCard=true;
            //         this.select1();
            //     }).catch(data => {
            //     });
            // },
            apipu(row){
                let _this = this;
                _this.sale=row;

               //console.log(row);
                // if(_this.sale.priuserdefnvc5=='0'){
                //     _this.$XModal.message({ message: "请先做代发校验!", status: 'info' })
                //     return;
                // }
                
                //console.log(_this.paramssyc.details)
                // if(_this.paramssyc.details.length==0||_this.paramssyc.details.length>1){
                //      _this.$XModal.message({ message: "供应商只能勾选一个!", status: 'error' })
                //     return 
                // }
               // _this.sale.assign()
                // _this.sale.partnercode = _this.paramssyc.details[0].code;
                // console.log(_this.sale);
                ax.base.post("/syc1/apisaletopuorder", _this.sale).then(data => {
                    //console.log(data.data.rs);
                    if(data.data.rs == 'null'){
                        _this.$XModal.message({ message: "下单成功!", status: 'success' })
                        _this.select1();
                    }else{
                        _this.$XModal.message({ message: data.data.rs, status: 'error' })
                    }
                  
                }).catch(data => {
                    console.log(data);
                    _this.$XModal.message({ message: data.data, status: 'error' })
                });
            },
            // sycupdate(row){
            //     let _this = this;
            //     // alert(_this.row.priuserdefnvc5)
            //     // if(_this.row.priuserdefnvc5=='0'){
            //     //     _this.$XModal.message({ message: "请先做代发校验!", status: 'info' })
            //     // }
            //     ax.base.post("/saiz1/apipuorderupdate", row).then(data => {
            //         console.log(data.data.rs);
            //         if(data.data.rs == 'null'){
            //             _this.$XModal.message({ message: "校验结束!", status: 'success' })
            //             _this.select1();
            //         }else{
            //             _this.$XModal.message({ message: data.data.rs, status: 'error' })
            //         }
                  
            //     }).catch(data => {
            //         console.log(data);
            //         _this.$XModal.message({ message: data.data, status: 'error' })
            //     });
            // },
            select1() {
                let _this = this
                    var p1={
                    code:""
                }
                p1.code=_this.p.code;
                // if(_this.checkList1.length > 0){
                //      p1.acc=_this.checkList1;
                //      console.log("1");
                // }else{
                //      p1.acc=_this.checkList;
                //      console.log("2");
                // }
                ax.base.post("/syc1/selectSaleorder1", p1).then(data => {
                   // console.log(data.data)
                    _this.rs = data.data;
                }).catch(data => {
                });
            },
            rowclick({ row, rowIndex, $rowIndex, column, columnIndex, $columnIndex, $event }) {
                console.log(row);
                let _this = this
                ax.base.post("/syc1/selectSaleorderb", {"id":row.id,"db":row.db}).then(data => {
                    _this.rs1 = data.data;
                }).catch(data => {
                });
                //  ax.base.post("/saiz1/selectcusb1", {"id":row.id,"db":row.db}).then(data => {
                //     _this.rs2 = data.data;
                // }).catch(data => {
                // });
            },
            pagechange({ currentPage, pageSize }) {
                this.params.page.current = currentPage;
                this.params.page.size = pageSize;
                this.select();
            },
            // syc() {
            //     let _this = this;
            //     console.log(_this.paramssyc)
            //     //return;
            //     ax.base.post("/saiz1/sycSaledeliveryclass",_this.paramssyc).then(data=>{
            //         _this.$XModal.message({ message: data.data, status: 'success' })
            //     })
            //     .catch(data=>{
            //         _this.$XModal.message({ message: data.data, status: 'error' })
            //     })
            // },
            selecta({ records }) {
                this.paramssyc.details = records;
            },
            selectb({ records }) {
                this.paramssyc.classes = records;
            },
        }
    }
</script>
<style scoped>
    .el-header,
    .el-footer {
        /* background-color: #b3c0d1; */
        color: #333;
        /* text-align: center; */
        /* line-height: 20px; */
    }

    .el-main {
        background-color: #e9eef3;
        color: #333;
        /* text-align: center; */
        line-height: 400px;
    }

    body>.el-container {
        margin-bottom: 40px;
    }

    .el-container:nth-child(7) .el-aside {
        line-height: 320px;
    }
</style>